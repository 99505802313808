import { AxiosInstance, AxiosRequestConfig } from 'axios'
import {
    CreateSubCustomerRequest,
    CreateTakerRequest,
    CreateUserRequest,
    CustomerDetail,
    GetCustomer,
    GetRolePermission,
    InternalUser,
    InternalUserDetail,
    InternalUserDetailResponse,
    Role,
    Roles,
    SubCustomerDetail,
    SubCustomerDetailResponse,
    UpdateCustomerRequest,
    UpdateRoleRequest,
    UpdateSubCustomerRequest,
    UpdateUserLocaleRequest,
    UpdateUserRequest,
    UserCountry,
    UserCustomer,
    UserCustomerResponse,
    UserCustomerStatus,
    UserCustomerTrader,
    UserResetPasswordRequest,
    UserRole,
    UserSetNewPasswordRequest
} from './UserService.types'
import { MakeAxiosPaginatedResponse, MakeAxiosResponse } from '../../Service.types'
import { serialize } from 'object-to-formdata'
import { undefinedValuesAsNullValues } from '../../../utils/ObjectHelper'

export const getTakersPage = async (
    axiosInstance: AxiosInstance,
    query: string,
    config: AxiosRequestConfig = {}
): Promise<MakeAxiosPaginatedResponse<UserCustomer>> => {
    const { data } = await axiosInstance.get<MakeAxiosPaginatedResponse<UserCustomer>>(`/user/external${query}`, config)
    return data
}

export const getInternalUsersPage = async (
    axiosInstance: AxiosInstance,
    query: string,
    config: AxiosRequestConfig = {}
): Promise<MakeAxiosPaginatedResponse<InternalUser>> => {
    const { data } = await axiosInstance.get<MakeAxiosPaginatedResponse<InternalUser>>(`user/internal${query}`, config)
    return data
}

export const getTraders = async (
    axiosInstance: AxiosInstance,
    config: AxiosRequestConfig = {}
): Promise<UserCustomerTrader[]> => {
    const {
        data: { data }
    } = await axiosInstance.get<MakeAxiosResponse<UserCustomerTrader[]>>('/user/trader', config)
    return data
}

export const getStatuses = async (
    axiosInstance: AxiosInstance,
    config: AxiosRequestConfig = {}
): Promise<UserCustomerStatus[]> => {
    const {
        data: { data }
    } = await axiosInstance.get<MakeAxiosResponse<UserCustomerStatus[]>>('/user/status', config)
    return data
}

export const getCountries = async (
    axiosInstance: AxiosInstance,
    config: AxiosRequestConfig = {}
): Promise<UserCountry[]> => {
    const {
        data: { data }
    } = await axiosInstance.get<MakeAxiosResponse<UserCountry[]>>('/user/country', config)
    return data
}

export const getInternalRoles = async (
    axiosInstance: AxiosInstance,
    config: AxiosRequestConfig = {}
): Promise<UserRole[]> => {
    const {
        data: { data }
    } = await axiosInstance.get<MakeAxiosResponse<UserRole[]>>('user/role/internal', config)
    return data
}

export const getExternalRoles = async (
    axiosInstance: AxiosInstance,
    config: AxiosRequestConfig = {}
): Promise<UserRole[]> => {
    const {
        data: { data }
    } = await axiosInstance.get<MakeAxiosResponse<UserRole[]>>('user/role/external', config)
    return data
}

export const createSubCustomer = async (
    axiosInstance: AxiosInstance,
    request: CreateSubCustomerRequest,
    config: AxiosRequestConfig = {}
): Promise<SubCustomerDetailResponse> => {
    const formData = serialize(request, {
        booleansAsIntegers: true,
        indices: true
    })
    formData.append('_method', 'post')

    config = {
        ...config,
        headers: {
            ...config.headers,
            'Content-Type': 'multipart/form-data'
        }
    }

    const response = await axiosInstance.post('/user/subcustomer', formData, config)
    return response.data
}

export const getCustomers = async (
    axiosInstance: AxiosInstance,
    config: AxiosRequestConfig = {}
): Promise<GetCustomer[]> => {
    const {
        data: { data }
    } = await axiosInstance.get<MakeAxiosResponse<GetCustomer[]>>('user/customer', config)
    return data
}

export const userResetPassword = async (
    axiosInstance: AxiosInstance,
    request: UserResetPasswordRequest,
    config: AxiosRequestConfig = {}
): Promise<void> => {
    await axiosInstance.post<void>('/user/forgot-password ', request, config)
}

export const userSetNewPassword = async (
    axiosInstance: AxiosInstance,
    request: UserSetNewPasswordRequest,
    config: AxiosRequestConfig = {}
): Promise<void> => {
    await axiosInstance.post<void>('/user/reset-password', request, config)
}

export const createTaker = async (
    axiosInstance: AxiosInstance,
    request: CreateTakerRequest,
    config: AxiosRequestConfig = {}
): Promise<UserCustomerResponse> => {
    const formData = serialize(undefinedValuesAsNullValues(request), {
        booleansAsIntegers: true,
        indices: true
    })
    formData.append('_method', 'post')

    config = {
        ...config,
        headers: {
            ...config.headers,
            'Content-Type': 'multipart/form-data'
        }
    }

    const response = await axiosInstance.post('/user/customer', formData, config)
    return response.data
}

export const createUser = async (
    axiosInstance: AxiosInstance,
    request: CreateUserRequest,
    config: AxiosRequestConfig = {}
): Promise<InternalUserDetailResponse> => {
    const formData = serialize(undefinedValuesAsNullValues(request), {
        booleansAsIntegers: true,
        indices: true
    })
    formData.append('_method', 'post')

    config = {
        ...config,
        headers: {
            ...config.headers,
            'Content-Type': 'multipart/form-data'
        }
    }

    const response = await axiosInstance.post('/user/internal', formData, config)
    return response.data
}

export const getSubCustomerDetail = async (
    axiosInstance: AxiosInstance,
    id: string,
    config: AxiosRequestConfig = {}
): Promise<SubCustomerDetail> => {
    const {
        data: { data }
    } = await axiosInstance.get<MakeAxiosResponse<SubCustomerDetail>>(`user/subcustomer/${id}`, config)
    return data
}

export const getInternalUserDetail = async (
    axiosInstance: AxiosInstance,
    id: string,
    config: AxiosRequestConfig = {}
): Promise<InternalUserDetail> => {
    const {
        data: { data }
    } = await axiosInstance.get<MakeAxiosResponse<InternalUserDetail>>(`user/internal/${id}`, config)
    return data
}

export const getCustomerDetail = async (
    axiosInstance: AxiosInstance,
    id: string,
    config: AxiosRequestConfig = {}
): Promise<CustomerDetail> => {
    const {
        data: { data }
    } = await axiosInstance.get<MakeAxiosResponse<CustomerDetail>>(`user/customer/${id}`, config)
    return data
}

export const updateCostumer = async (
    axiosInstance: AxiosInstance,
    id: string,
    request: UpdateCustomerRequest,
    config: AxiosRequestConfig = {}
): Promise<UserCustomerResponse> => {
    const formData = serialize(undefinedValuesAsNullValues(request), {
        booleansAsIntegers: true,
        indices: true
    })
    formData.append('_method', 'post')

    config = {
        ...config,
        headers: {
            ...config.headers,
            'Content-Type': 'multipart/form-data'
        }
    }

    const res = await axiosInstance.post(`user/customer/${id}`, formData, config)
    return res.data
}

export const updateSubCustomer = async (
    axiosInstance: AxiosInstance,
    id: string,
    request: UpdateSubCustomerRequest,
    config: AxiosRequestConfig = {}
): Promise<SubCustomerDetailResponse> => {
    const formData = serialize(request, {
        booleansAsIntegers: true,
        indices: true
    })
    formData.append('_method', 'post')

    config = {
        ...config,
        headers: {
            ...config.headers,
            'Content-Type': 'multipart/form-data'
        }
    }

    const res = await axiosInstance.post(`/user/subcustomer/${id}`, formData, config)
    return res.data
}

export const updateUser = async (
    axiosInstance: AxiosInstance,
    id: string,
    request: UpdateUserRequest,
    config: AxiosRequestConfig = {}
): Promise<InternalUserDetailResponse> => {
    const res = await axiosInstance.post(`/user/internal/${id}`, request, config)
    return res.data
}

export const updateUserLocale = async (
    axiosInstance: AxiosInstance,
    request: UpdateUserLocaleRequest,
    config: AxiosRequestConfig = {}
): Promise<void> => {
    await axiosInstance.patch<void>('/user/locale', request, config)
}

export const getRoles = async (axiosInstance: AxiosInstance, config: AxiosRequestConfig = {}): Promise<Roles[]> => {
    const {
        data: { data }
    } = await axiosInstance.get<MakeAxiosResponse<Roles[]>>('user/role', config)
    return data
}

export const getRolePermission = async (
    axiosInstance: AxiosInstance,
    config: AxiosRequestConfig = {}
): Promise<GetRolePermission[]> => {
    const {
        data: { data }
    } = await axiosInstance.get<MakeAxiosResponse<GetRolePermission[]>>('user/role/permission', config)
    return data
}

export const getRole = async (
    axiosInstance: AxiosInstance,
    id: string,
    config: AxiosRequestConfig = {}
): Promise<Role> => {
    const {
        data: { data }
    } = await axiosInstance.get<MakeAxiosResponse<Role>>(`user/role/${id}`, config)
    return data
}

export const updateRole = async (
    axiosInstance: AxiosInstance,
    id: string,
    request: UpdateRoleRequest,
    config: AxiosRequestConfig = {}
) => {
    await axiosInstance.patch(`user/role/${id}`, request, config)
}

export const deleteUser = async (axiosInstance: AxiosInstance, id: string, config: AxiosRequestConfig = {}) => {
    return axiosInstance.delete(`user/${id}`, config)
}
